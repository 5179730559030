import { useEffect, useRef, useState } from "react";
import BoxDisplay from "../components/boxDisplay/boxDisplay";
// import { useData } from "../components/dataProvider/dataProvider";
import useMyFetch from "../components/fetch/fetch";
import toast from "react-hot-toast";
import ImagePicker from "../components/inputBox/imagePicker";
import PopupBox from "../components/popup/popup";
import { InputBox2, RangeInputBox } from "../components/inputBox/inputBox";
import PopupPage from "../components/popup/popupPage";
import "./lottery.css";
import { useData } from "../components/dataProvider/dataProvider";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Lottery = () => {
  const {
    userInfo,
    setLoadingScreen,
    setIsTicket,
    setTicketInfo,
    setMenuExtended,
    setUserList,
    userList,
  } = useData();
  const { post, post2 } = useMyFetch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showPopup, setShowPopup] = useState({
    newLottery: false,
    editLottery: false,
    deleteLottery: false,
  });

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [time, setTime] = useState("");
  const [price, setPrice] = useState("");
  const [rangeStart, setRangeStart] = useState("");
  const [rangeEnd, setRangeEnd] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [newImage, setNewImage] = useState(false);

  const nameInput = useRef(null);
  const timeInput = useRef(null);
  const priceInput = useRef(null);
  const rangeStartInput = useRef(null);
  const rangeEndInput = useRef(null);
  const typeInput = useRef(null);
  const dateInput = useRef(null);
  // const phoneInput = useRef(null);
  const imageInput = useRef(null);

  useEffect(() => {
    if (parseInt(userInfo.role) === 0) {
      post("employee/list").then((response) => {
        if (response.status !== "success") {
          toast.error(response.message);
          return;
        }
        setUserList(response.data);
      });
    }
    post("lottery").then((response) => {
      if (response.status !== "success") {
        toast.error(response.message);
        return;
      }
      if (Array.isArray(response.data)) {
        setData(response.data);
        setLoading(false);
      } else {
        const dataArray = Object.keys(response.data).map((key) => response.data[key]);
        setData(dataArray);
      }
    });
    // const getUserData = () => {
    //   if (parseInt(userInfo.role) === 0) {
    //     post("employee/list").then((response) => {
    //       if (response.status !== "success") {
    //         toast.error(response.message);
    //         return;
    //       }
    //       setUserList(response.data);
    //     });
    //   }
    // };
    // getUserData();
    // const intervalId = setInterval(getUserData, 3000);
    // return () => clearInterval(intervalId);
  }, []);

  function KeyPressHandler(event, nextRef, isLast = false) {
    if (
      event.target.name === "phone" ||
      event.target.name === "rangeStart" ||
      event.target.name === "rangeEnd" ||
      event.target.name === "time"
    ) {
      if (!event.key.match(/^\d$/)) {
        event.preventDefault();
      }
    }
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.value !== "") {
        if (isLast) {
          if (showPopup.editLottery) {
            confirmEdit();
            return;
          }
          ConfirmNewLottery();
        }
        if (event.target.name === "rangeEnd") {
          nextRef.current.childNodes[1].childNodes[0].click();
          return;
        }
        if (nextRef && nextRef.current) {
          nextRef.current.focus();
        }
      }
    }
  }

  const inputCheck = () => {
    if (name === "") {
      toast.error("Name Cannot be Empty");
      nameInput.current.focus();
      return false;
    }
    if (time === "") {
      toast.error("Time(s) Cannot be Empty");
      timeInput.current.focus();
      return false;
    }
    if (price === "") {
      toast.error("Price be Empty");
      priceInput.current.focus();
      return false;
    }
    // if (phoneNumber === "") {
    //   toast.error("Phone Number Cannot be Empty");
    //   phoneInput.current.focus();
    //   return false;
    // }
    if (type === "") {
      toast.error("Type Cannot be Empty");
      typeInput.current.focus();
      return false;
    }
    if (date === "") {
      toast.error("Open Date Cannot be Empty");
      typeInput.current.focus();
      return false;
    }
    console.log(rangeStart, rangeEnd);
    if (rangeStart === "") {
      toast.error("Range cannot be Empty Cannot be Empty");
      rangeStartInput.current.focus();
      return false;
    }
    if (rangeEnd === "") {
      toast.error("Range cannot be Empty Cannot be Empty");
      rangeEndInput.current.focus();
      return false;
    }
    if (selectedImage === "") {
      toast.error("Image Cannot be Empty");
      imageInput.current.childNodes[1].childNodes[0].click();
      return false;
    }
    return true;
  };

  const newLottery = () => {
    console.log("new Lottery");
    setName("");
    setTime("");
    setPrice("");
    setRangeStart("");
    setRangeEnd("");
    setType("");
    setDate("");
    // setPhoneNumber("");
    setSelectedImage("");
    setNewImage(false);
    setShowPopup({ ...showPopup, newLottery: true });
  };
  const ConfirmNewLottery = () => {
    if (!inputCheck()) return;
    console.log("Confirm new Lottery");
    setLoadingScreen(true);
    console.log(selectedImage);
    post("lottery/add", {
      name,
      time,
      price,
      date,
      type,
      range_start: rangeStart,
      range_end: rangeEnd,
      img: selectedImage,
    }).then((response) => {
      setLoadingScreen(false);
      if (response.status !== "success") {
        toast.error(response.message);
        if (response.message === "Lottery Name Already Exist") nameInput.current.focus();
        return;
      }
      setData([...data, response.data]);
      setShowPopup({ ...showPopup, newLottery: false });
    });
  };

  const handleEdit = (id) => {
    console.log(id);
    const lottery = data.filter((item) => item.id === id)[0];
    setId(id);
    setName(lottery.name);
    setTime(lottery.time);
    setPrice(lottery.price);
    setRangeStart(lottery.range_start);
    setRangeEnd(lottery.range_end);
    setType(lottery.type);
    setDate(lottery.date);
    // setPhoneNumber(lottery.ph_no);
    setSelectedImage(lottery.img);
    setNewImage(false);
    setShowPopup({ ...showPopup, editLottery: true });
  };
  const confirmEdit = () => {
    if (!inputCheck()) return;
    console.log("confirm Edit");
    setLoadingScreen(true);
    let postData;
    if (newImage) {
      postData = {
        id,
        img: selectedImage,
        name,
        time,
        price,
        date,
        type,
        range_start: rangeStart,
        range_end: rangeEnd,
        newImage,
      };
    } else {
      postData = {
        id,
        name,
        time,
        price,
        // ph_no: phoneNumber,
        date,
        type,
        range_start: rangeStart,
        range_end: rangeEnd,
        newImage,
      };
    }
    console.log(postData);
    post("lottery/edit", postData).then((response) => {
      setLoadingScreen(false);
      if (response.status !== "success") {
        toast.error(response.message);
        if (response.message === "Lottery Name already exist") nameInput.current.focus();
        return;
      }
      setData(data.map((item) => (item.id === id ? response.data : item)));
      setShowPopup({ ...showPopup, editLottery: false });
      // console.log(data);
    });
  };
  const handleDelete = (id) => {
    console.log(id);
    setId(id);
    setShowPopup({ ...showPopup, deleteLottery: true });
  };
  const ConfirmDeleteLottery = () => {
    console.log("Confirm Delete Trigger for", id);
    // setLoadingScreen(true);
    post("lottery/delete", { id: id }).then((response) => {
      if (response.status !== "success") {
        toast.error(response.message);
        return;
      }
      post("ticket/delete", { id: id }).then((response) => {
        setLoadingScreen(false);
        if (response.status !== "success") {
          toast.error(response.message);
          return;
        }
        setData(data.filter((item) => item.id !== id));
        setShowPopup({ ...showPopup, deleteLottery: false });
      });
    });
  };

  const handleOpen = (id) => {
    console.log(id);
    setTicketInfo(data.filter((item) => item.id === id)[0]);
    // setMenuExtended(false);
    setIsTicket(1);
  };

  const exportToExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, `${fileName}.xlsx`);
  };

  const handleDownload = (id) => {
    console.log("Exporting Lottery : ", id);
    setLoadingScreen(true);
    const date = new Date();
    post("ticket", { lottery: id }).then((res) => {
      if (res.status !== "success") {
        toast.error(res.message);
        setLoadingScreen(false);
        return;
      }
      post("employee/list").then((response) => {
        if (response.status !== "success") {
          toast.error(response.message);
          return;
        }
        setUserList(response.data);
        exportToExcel(
          res.data.map((row) => ({
            "Ticket No": String(row["ticket_number"]).padStart(
              data.filter((item) => item.id === id)[0]["range_end"].length,
              "0"
            ),
            Name: row["cus_name"],
            "Phone No": row["cus_ph"],
            Address: row["cus_address"],
            Result: row["state"] === "1" ? "Sold" : row["state"] === "2" ? "reserved" : "",
            Time: row["at"],
            Sold_by: userList.filter((item) => item.id === row["by_who"])[0]["name"],
          })),
          date.toLocaleDateString() + " " + data.filter((item) => item.id === id)[0]["name"]
        );
        setLoadingScreen(false);
      });
    });
  };

  return (
    <div className="lottery">
      <div className="page-title">
        <h2>Lottery List</h2>
      </div>

      <BoxDisplay
        data={data}
        actions={[
          { type: "download", handler: handleDownload },
          { type: "edit", handler: handleEdit },
          { type: "delete", handler: handleDelete },
        ]}
        is_loading={loading}
        newLottery={newLottery}
        user={userInfo["role"]}
        onClick={handleOpen}
      />

      {(showPopup.editLottery || showPopup.newLottery) && (
        <PopupPage
          title={showPopup.newLottery ? "Add Lottery" : "Edit Lottery"}
          formName="new-Lottery"
          color="primary"
          close={() => setShowPopup({ ...showPopup, newLottery: false, editLottery: false })}
          actions={[
            { type: "cancel", name: "Cancel" },
            {
              type: "success",
              name: showPopup.editLottery ? "Save" : "Create",
              func: showPopup.editLottery ? confirmEdit : ConfirmNewLottery,
            },
          ]}
        >
          <InputBox2
            type="text"
            name="name"
            display="Name"
            value={name}
            setValue={setName}
            isRequired={true}
            ref={nameInput}
            onKeyPress={(e) => KeyPressHandler(e, timeInput)}
            placeholder="Enter Lottery Name"
            autoComplete="off"
          />
          <InputBox2
            type="text"
            name="time"
            display="Time(s)"
            value={time}
            setValue={setTime}
            isRequired={true}
            ref={timeInput}
            onKeyPress={(e) => KeyPressHandler(e, priceInput)}
            placeholder="အကြိမ်အရေအတွက်"
            autoComplete="off"
          />
          <InputBox2
            type="number"
            name="price"
            display="Ticket Price"
            value={price}
            setValue={setPrice}
            isRequired={true}
            ref={priceInput}
            onKeyPress={(e) => KeyPressHandler(e, typeInput)}
            placeholder="Enter Lottery Price"
            autoComplete="off"
          />
          {/* <InputBox2
            type="text"
            name="phone"
            display="Phone Number"
            value={phoneNumber}
            setValue={setPhoneNumber}
            isRequired={true}
            ref={phoneInput}
            onKeyPress={(e) => KeyPressHandler(e, typeInput)}
            placeholder="Enter Phone Number"
            autoComplete="off"
          /> */}
          <InputBox2
            type="text"
            name="type"
            display="Type"
            value={type}
            setValue={setType}
            isRequired={true}
            ref={typeInput}
            onKeyPress={(e) => KeyPressHandler(e, dateInput)}
            placeholder="Enter Lottery Price Type"
            autoComplete="off"
          />
          <InputBox2
            type="text"
            name="date"
            display="Open Date"
            value={date}
            setValue={setDate}
            isRequired={true}
            ref={dateInput}
            onKeyPress={(e) => KeyPressHandler(e, rangeStartInput)}
            placeholder="Enter Lottery Open Date"
            autoComplete="off"
          />
          <RangeInputBox
            name="rangeStart"
            name2="rangeEnd"
            display="Ticket Range"
            value={rangeStart}
            value2={rangeEnd}
            setValue={setRangeStart}
            setValue2={setRangeEnd}
            isRequired={true}
            isRequired2={true}
            ref={[rangeStartInput, rangeEndInput]}
            placeholder="000"
            placeholder2="999"
            onKeyPress1={(e) => KeyPressHandler(e, rangeEndInput)}
            onKeyPress2={(e) => KeyPressHandler(e, imageInput)}
            autoComplete="off"
          />
          <ImagePicker
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            setNewImage={setNewImage}
            ref={imageInput}
          />
        </PopupPage>
      )}
      {showPopup.deleteLottery && (
        <PopupBox
          formName="delete-Lottery row"
          color="danger"
          close={() => setShowPopup({ ...showPopup, deleteLottery: false })}
          actions={[
            { type: "cancel", name: "Cancel" },
            {
              type: "danger",
              name: "Delete",
              func: ConfirmDeleteLottery,
            },
          ]}
        >
          <div className="trash-can">
            <svg viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.31258 25.8345C2.31258 27.5489 3.76571 28.9516 5.54175 28.9516H18.4584C20.2345 28.9516 21.6876 27.5489 21.6876 25.8345V7.13172H2.31258V25.8345ZM23.3022 2.45603H17.6511L16.0365 0.897461H7.96362L6.34904 2.45603H0.697998V5.57315H23.3022V2.45603Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="right">
            <h3>Are you sure?</h3>
            <p>
              Do you really want to delete this Lottery?
              <br /> This process cannot be undone.
            </p>
          </div>
        </PopupBox>
      )}
    </div>
  );
};

export default Lottery;
